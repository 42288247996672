<template>
  <a-row>
    <a-col :lg="24">
      <data-table
        :columns="tableColumns"
        :data="dataPaginate"
        :totalRecords="data.length"
        v-on:getCurrentPaginator="getDataFromPaginatorNumber"
      />
    </a-col>
  </a-row>

</template>

<script>
import { defineAsyncComponent } from 'vue';

const DataTable = defineAsyncComponent(() => import('@/view/components/DataTable/DataTable'));

export default {
  name: "UsersFromCSVTable",
  components:{
    DataTable
  },
  props:{
    data: Object
  },
  data() {
    return {
      tableColumns :[
        {
          title: 'CURP',
          dataIndex: 'curp',
          key: 'curp',
        },
        {
          title: 'Nombre(s)',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Apellido paterno',
          dataIndex: 'last_name',
          key: 'last_name',
        },
        {
          title: 'Correo electrónico',
          dataIndex: 'email',
          key: 'email',
        },

      ],
      rowsPerPage: 10,
      dataPaginate:[],
      limit: 0,
      start: 0,
    }
  },
  mounted() {
    //this.dataPaginate = this.data.slice(0, 2)
    this.getDataFromPaginatorNumber(1);
  },
  methods:{
    getDataFromPaginatorNumber(page){
      this.start = page ==1 ? page-1 : (page-1)*this.rowsPerPage;
      this.limit = page ==1 ? this.rowsPerPage : this.limit +this.rowsPerPage;

      this.dataPaginate = this.data.slice(this.start, this.limit)
    }
  },

};
</script>

<style>

</style>